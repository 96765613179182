// import formatDistance from "date-fns/formatDistance";
// import { es } from "date-fns/locale";
export const getMinutesFromNow2 = (date) => {
  if (!date) return "-";

  var ahora = new Date();
  var antes = new Date(date);
  var diffMs = ahora - antes; // milliseconds between now & Christmas
  var minutos = Math.round(diffMs / 60000);
  return minutos;
};
