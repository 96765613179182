import { URL_BASE_ACCESOCLIENTES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, postFile } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetAsistencias = () => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ACCESOCLIENTES}/GetAsistencias/${usuarioID}`;

    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeAsistencias(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get asistencias",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeAsistencias = (asistencias) => ({
  type: types.storeAsistencias,
  payload: asistencias,
});

////////////////////////

export const startGetAsistencia = (asistenciaID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ACCESOCLIENTES}/GetAsistencia/${usuarioID}/${asistenciaID}`;

    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeAsistencia(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get asistencia",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeAsistencia = (asistencia) => ({
  type: types.storeAsistencia,
  payload: asistencia,
});
