import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { LabelValor } from "../../../components/label/LabelValor";
import { dateFromString } from "../../../utils/dateFromString";

export const Datos = () => {
  const { asistencia: a } = useSelector((state) => state.asistenciasReducer);
  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelValor label="Nombre" valor={`${a.nombre}`} />
        </Col>
        <Col span={12}>
          <LabelValor
            label="Cita"
            valor={`${dateFromString(a.fechaCita).dateStep}`}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelValor label="Validador1" valor={a.validador1} />
        </Col>
        <Col span={12}>
          <LabelValor label="Validador2" valor={a.validador2} />
        </Col>
        <Col span={12}>
          <LabelValor label="Operador" valor={a.operador} />
        </Col>
        <Col span={12}>
          <LabelValor label="Servicio" valor={a.servicio} />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={6} style={{ textAlign: "center" }}>
          <LabelValor
            label="Apertura"
            valor={dateFromString(a.fechaLlamadaIniciada).dateStep}
          />
        </Col>

        <Col span={6} style={{ textAlign: "center" }}>
          <LabelValor
            label="Capturado"
            valor={dateFromString(a.fechaCapturaTerminada).soloHora}
          />
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <LabelValor
            label="Asignado"
            valor={dateFromString(a.fechaAsignacion).soloHora}
          />
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <LabelValor
            label="Arribo"
            valor={dateFromString(a.fechaArribo).soloHora}
          />
        </Col>

        <Col span={24}>
          <LabelValor label="Origen" valor={a.direccionOrigen} />
        </Col>
        <Col span={24}>
          <LabelValor label="Destino" valor={a.direccionDestino} />
        </Col>
      </Row>
    </>
  );
};
