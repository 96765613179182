import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { startLogoff } from "../context/actions/loginActions";
import { PoweroffOutlined, PhoneOutlined } from "@ant-design/icons";

////
export default function MenuLayout() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = ({ key }) => {
    if (key !== "/logoff") history.push(key);
    else dispatch(startLogoff());
  };

  return (
    <Menu
      onClick={handleClick}
      style={{ marginTop: 10 }}
      theme="light"
      defaultSelectedKeys={["0"]}
      mode="inline"
    >
      <Menu.Item key="/" icon={<PhoneOutlined />}>
        Asistencias
      </Menu.Item>

      <Menu.Item key="/logoff" icon={<PoweroffOutlined />}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );
}
