import { CheckCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const EsCita = ({ esCita }) => {
  if (esCita === "Si")
    return (
      <Tooltip title="Es Cita">
        <CheckCircleOutlined style={{ fontSize: 17, color: "green" }} />
      </Tooltip>
    );
  return <div>-</div>;
};
