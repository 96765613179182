export const types = {
  setLoadingStatus: "[loadingAction] setLoadingStatus",
  setCalculing: "[loadingAction] setCalculing",
  setLoginErr: "[authErrAcion] setLoginErr",
  storeUserData: "[loginAcion] storeUserData",
  setLogged: "[loginAcion] setLogged",

  //// notificaciones
  notification: "[notificacionAction] notification",
  storeVersionBackend: "[notificacionAction] storeVersionBackend",

  //// asistencias
  storeAsistencias: "[asistenciasAction] storeAsistencias",
  storeAsistencia: "[asistenciasAction] storeAsistencia",
};
