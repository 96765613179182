import React from "react";

export const LabelValor = ({ label, valor }) => {
  return (
    <div>
      <div className="label">{label}</div>
      <div className="valor">{valor}</div>
    </div>
  );
};
