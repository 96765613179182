import { URL_BASE_VER } from "../../constants/url";
import { types } from "../types/types";
import { get, post, postFile } from "../../utils/fetch";

import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";

export const notification = (body) => ({
  type: types.notification,
  payload: body,
});

//////////////

export const startGetVer = () => {
  return async (dispatch) => {
    const result = await get(URL_BASE_VER);
    if (result.code === 0) {
      dispatch(storeVersionBackend(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Ver",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeVersionBackend = (payload) => ({
  type: types.storeVersionBackend,
  payload,
});
