import React from "react";
import { useSelector } from "react-redux";
import ModalPage from "../../../layout/ModalPage";
import { Datos } from "./Datos";

export const ModalDetalle = ({ show, close }) => {
  const { asistencia } = useSelector((state) => state.asistenciasReducer);
  const handleOk = () => close();
  return (
    <ModalPage
      isVisible={show}
      titulo={`Datos de Asistencia ${asistencia.asistenciaID}`}
      handleCancel={() => close()}
      handleOk={handleOk}
    >
      <Datos />
    </ModalPage>
  );
};
