import React from "react";
import { TablaHeader } from "./TablaHeader";
import { TablaItem } from "./TablaItem";

export const Tabla = () => {
  return (
    <>
      <TablaHeader />
      <TablaItem />
    </>
  );
};
