import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { startGetAsistencia } from "../../context/actions/asistenciasActions";
import { dateFromString } from "../../utils/dateFromString";
import { EsCita } from "./EsCita";
import { FechaRowAsignacion } from "./FechaRowAsignacion";
import { LocalCarretero } from "./LocalCarretero";
import { ModalDetalle } from "./modalDetalle/ModalDetalle";

export const TablaItem = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const { asistencias } = useSelector((state) => state.asistenciasReducer);
  const rowClick = (asistenciaID) => {
    setShow(true);
    dispatch(startGetAsistencia(asistenciaID));
  };
  return (
    <>
      {asistencias.map((a, i) => (
        <Row
          gutter={10}
          onClick={() => rowClick(a.asistenciaID)}
          key={i}
          style={{ marginBottom: 5, fontSize: 11 }}
          className="rowAsistencias"
        >
          <Col span={2} className="rowNormalTabla">
            {a.asistenciaID}
          </Col>
          <Col span={2} className="rowNormalTabla">
            <div className="rowFechaTabla">
              {dateFromString(a.fechaCapturaTerminada).dateStep}
            </div>
          </Col>
          <Col span={2} className="rowNormalTabla">
            <FechaRowAsignacion asistencia={a} />
          </Col>
          <Col span={2} className="rowNormalTabla">
            <div className="rowFechaTabla">
              {dateFromString(a.fechaArribo).soloHora || "-"}
            </div>
          </Col>
          <Col span={4} className="rowNormalTabla">
            {a.titular}
          </Col>
          <Col span={4} className="rowNormalTabla">
            {a.servicio}
          </Col>
          <Col span={1} className="rowNormalTabla">
            <EsCita esCita={a.esCita} />
          </Col>
          <Col span={2} className="rowNormalTabla">
            <LocalCarretero localCarretero={a.localCarretero} />
          </Col>
          <Col span={5} className="rowNormalTabla">
            {a.origen}
          </Col>
        </Row>
      ))}
      <ModalDetalle show={show} close={() => setShow(false)} />
    </>
  );
};
