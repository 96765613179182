import { types } from "../types/types";

const initial = {
  asistencias: [],
  asistencia: {},
};

export const asistenciasReducer = (state = initial, action) => {
  switch (action.type) {
    case types.storeAsistencia:
      return {
        ...state,
        asistencia: action.payload,
      };
    case types.storeAsistencias:
      return {
        ...state,
        asistencias: action.payload,
      };

    default:
      return state;
  }
};
