import { Spin } from "antd";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { consLogged } from "../constants/consLogged";

import { LayoutPage } from "../layout/LayoutPage";
import Login from "../views/auth/Login";
import Home from "../views/home/Home";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Loading from "../components/loading/Loading";
import { useDispatch } from "react-redux";
import { verificaLogin } from "../context/actions/loginActions";

export default function Routes() {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(verificaLogin());
  }, []);

  if (logged === consLogged.INICIANDO) return <Loading />;

  return (
    <Router>
      <div
        style={{
          height: "100vh",
          width: 15,
          backgroundColor: "#71bf0a",
          position: "fixed",
          zIndex: 999,
        }}
      ></div>
      <Switch>
        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/login"
          component={Login}
        />
        <LayoutPage>
          <Suspense
            fallback={
              <div style={{ marginTop: 30 }}>
                {" "}
                <Spin size="large" />
              </div>
            }
          >
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/"
              component={Home}
            />
          </Suspense>
        </LayoutPage>
      </Switch>
    </Router>
  );
}
