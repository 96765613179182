import { HomeOutlined, NodeIndexOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const LocalCarretero = ({ localCarretero }) => {
  if (localCarretero === "local")
    return (
      <Tooltip title="Local">
        <HomeOutlined style={{ fontSize: 17, color: "green" }} />
      </Tooltip>
    );
  if (localCarretero === "carretero")
    return (
      <Tooltip title="Carretero">
        <NodeIndexOutlined style={{ fontSize: 17, color: "green" }} />
      </Tooltip>
    );

  return <div>-</div>;
};
