import { getMinutesFromNow } from "../../utils/getMinutesFromNow";
import { dateFromString } from "../../utils/dateFromString";
import { getMinutesFromNow2 } from "../../utils/getMinitesFromNow2";

export const FechaRowAsignacion = ({ asistencia }) => {
  let color;
  let minAhora;
  const { localCarretero, estatusID, esCita, fechaAsignacion } = asistencia;

  if (localCarretero === "local" && esCita === "No" && estatusID === 3) {
    minAhora = getMinutesFromNow2(fechaAsignacion);

    if (minAhora > 60 && minAhora < 90) color = "rowAmarillo";
    else if (minAhora >= 90) color = "rowRojo";
  }

  return (
    <div className={`rowFechaTabla ${color}`}>
      {dateFromString(fechaAsignacion).soloHora || "-"}
    </div>
  );
};
