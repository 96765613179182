import { asistenciasReducer } from "../reducers/asistenciasReducer";
import { authErrReducer } from "../reducers/authErrReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { userReducer } from "../reducers/userReducer";

export const allReducers = {
  notificationReducer: notificationReducer,
  userReducer: userReducer,
  authErrReducer: authErrReducer,
  asistenciasReducer:asistenciasReducer
};
