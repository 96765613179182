import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { startGetAsistencias } from "../../context/actions/asistenciasActions";
import CardPage from "../../layout/CardPage";
import { Tabla } from "./Tabla";

export default function Home() {
  const { asistencias } = useSelector((state) => state.asistenciasReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(startGetAsistencias());
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(startGetAsistencias());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  ///
  return (
    <CardPage titulo={` ${asistencias.length} Asistencias `}>
      <Tabla />
    </CardPage>
  );
}
