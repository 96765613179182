import { consLogged } from "../../constants/consLogged";
import { URL_LOGIN } from "../../constants/url";
import { post } from "../../utils/fetch";
import parseJwt from "../../utils/parseJwt";
import { types } from "../types/types";
import { setLoginErr } from "./authErrActions";

export const startLogin = (body) => {
  return async (dispatch) => {
    dispatch(setLoginErr(null));
    const respopnse = await post(URL_LOGIN, { ...body, app: "clientes" });

    if (respopnse.code !== -1) {
      localStorage.setItem("token", respopnse.data);
      dispatch(storeUserData(parseJwt(respopnse.data)));
      dispatch(setLogged(consLogged.LOGGED));
    } else {
      dispatch(setLoginErr(respopnse.error));
    }
  };
};

export const verificaLogin = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
      const u = parseJwt(token);

      dispatch(storeUserData(parseJwt(token)));
      dispatch(setLogged(consLogged.LOGGED));
    } else {
      dispatch(setLogged(consLogged.NOTLOGGED));
    }
  };
};

const storeUserData = (payload) => ({
  type: types.storeUserData,
  payload,
});

const setLogged = (payload) => ({
  type: types.setLogged,
  payload,
});

export const startLogoff = () => {
  return (dispatch) => {
    dispatch(setLogged(consLogged.NOTLOGGED));
    localStorage.removeItem("token");
  };
};
